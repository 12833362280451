import * as React from "react"
import Overlay from '../components/Overlay'
import Scene from '../components/Scene'
import Greetings from '../components/Greetings'
import Header from '../components/Header'
import { Helmet } from "react-helmet"

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link data-react-helmet='true' rel='icon' href='/favicon.png' />
      </Helmet>
      <main>
        <Header />
        <Scene />
        <Overlay />
        <Greetings />
      </main>
    </>
  )
}

export default IndexPage
