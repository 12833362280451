import React from 'react'
import './styles.scss'

const Greetings = () => {
  return (
    <div className="greetings-container">
      <h1>Gleðilega hátíð</h1>
      <p className="greet">
        Kærar þakkir fyrir samstarfið á þessu ári.
        Hlökkum til að hitta ykkur aftur á nýju ári og gera einhverja stafræna snilld saman!
      </p>
      <p className="call-to-action-text">Má ekki bjóða þér að skoða meira fínt sem Júní gerir?</p>
      <a className="action-link" href="https://www.juni.is/"><p>Smelltu þér á vefinn okkar</p></a>
    </div>
  )
}

export default Greetings