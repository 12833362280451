import React from 'react'
import "./styles.scss"

const Overlay = () => {
  return (
  	<div className="snow-container">
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
  		<div className="snow"></div>
    </div>
  )
}

export default Overlay