import React, { useState } from 'react'
import useMedia from '../../hooks/useMedia'
import useTimeout from '../../hooks/useTimeout'
import './styles.scss'

const Scene = () => {
  const [visible, setVisible] = useState(false)
  const isDesktop = useMedia('(min-width: 768px)')

  const show = () => setVisible(true)
  useTimeout(show, 5000)

  return (
    <div className={`scene-container ${isDesktop ? 'desktop' : 'mobile'}`}>
      <div
        className="loadText-container"
        style={visible ? { opacity: 0 } : { opacity: 1 }}
      >
        <p>Hringi inn jólunum, bíddu aðeins</p>
      </div>
      {isDesktop ? (
        <iframe
          src="https://my.spline.design/jolo-d0d40178ef240cefede7808301ce4ed5/"
          frameBorder="0"
          width="100%"
          height="100%"
          style={visible ? { opacity: 1 } : { opacity: 0 }}
        ></iframe>
      ) : (
        <iframe
          src="https://my.spline.design/jolomobile-154c30a6d692cdf616261a1dbfec0af9/"
          frameBorder="0"
          width="100%"
          height="100%"
          style={visible ? { opacity: 1 } : { opacity: 0 }}
        ></iframe>
      )}
    </div>
  )
}

export default Scene
